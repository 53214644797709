import React from "react";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet'
import Img from "gatsby-image";
import Footer from "../components/Footer";
import Customers from "../components/Customers";
import OurValue from "../components/OurValue";
import ContactForm from "../components/ContactForm";
import NavBar from "../components/NavBar";
import { Container, Row, Col, Image } from "react-bootstrap";
import KeepInformed from "../components/KeepInformed";
import ImageMeta from '../components/common/meta/ImageMeta'
import config from '../utils/siteConfig'
import coverImage from '../images/kubernetes.png'
import EcwidStore from '../components/EcwidStore'
// import "../styles/app2.css"

const pageTitle = "More Secure Store";
const pageDescription = config.siteDescriptionMeta;
const pageUrl = config.siteUrl + "/Store";

const Store = ({ data }) => {
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <link rel="canonical" href={pageUrl} />

                <meta property="og:site_name" content={config.siteName} />
                <meta property="og:type" content="page" />
                <meta property="og:title"
                    content={pageTitle}
                />
                <meta property="og:description"
                    content={pageDescription}
                />
                <meta property="og:url" content={pageUrl} />
                {/* <meta property="article:published_time" content={ghostPost.published_at} />
                <meta property="article:modified_time" content={ghostPost.updated_at} />
                {publicTags.map((keyword, i) => (<meta property="article:tag" content={keyword} key={i} />))}
                {author.facebookUrl && <meta property="article:author" content={author.facebookUrl} />} */}

                <meta name="twitter:title"
                    content={pageTitle}
                />
                <meta name="twitter:description"
                    content={pageDescription}
                />
                <meta name="twitter:url" content={pageUrl} />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="more_secure" />
                {/* {primaryTag && <meta name="twitter:label2" content="Filed under" />}
                {primaryTag && <meta name="twitter:data2" content="MoreSecure" />}

                {settings.twitter && <meta name="twitter:site" content={`https://twitter.com/${settings.twitter.replace(/^@/, ``)}/`} />}
                {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script> */}
            </Helmet>
            <ImageMeta image={coverImage} />
            <NavBar />
            <header className="header">
                <Container className="header__container">
                    <Row className="header__row">
                        <Col
                            xm={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="header__left-col"
                        >
                            <div className="header-wrapper">
                                <div className="main-sub-heading">
                                    Affordable Effective Secure
                                </div>
                                <div className="main-heading">Solution Store</div>
                                <KeepInformed />
                            </div>
                        </Col>
                        <Col className="header__right-col">
                            <Img
                                className="header__store-image"
                                fluid={data.storeImage.childImageSharp.fluid}
                                alt="Store Image"
                            />
                        </Col>
                    </Row>
                </Container>
                <svg
                    className="header__svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                >
                    <path
                        fill="#fff"
                        fillOpacity="1"
                        d="M0,96L60,90.7C120,85,240,75,360,90.7C480,107,600,149,720,149.3C840,149,960,107,1080,101.3C1200,96,1320,128,1380,144L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
                    ></path>
                </svg>
            </header>
            <section className="store">
                {/* <Container className="blog__container"> */}
            {/* <section className="infra"> */}
                {/* <div className="infra__blob-one"></div>
                <div className="infra__blob-two"></div>
                <div className="infra__blob-three"></div> */}
                {/* <Container className="infra__container">
                    <Row className="infra__row">
                        <Col className="infra__col">
                            <div className="heading">
                                More Secure Store
                            </div>
                        </Col>
                    </Row>
                </Container> */}
                <EcwidStore />
            </section>
            <OurValue />
            <Customers />
            <ContactForm />
            <Footer />
        </>
    );
};

export default Store;

export const query = graphql`
    query {
        storeImage: file(relativePath: { eq: "store.png" }) {
            childImageSharp {
                fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        kubernetesImage: file(relativePath: { eq: "kubernetes.png" }) {
            childImageSharp {
                fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        roadImage: file(relativePath: { eq: "road.png" }) {
            childImageSharp {
                fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        upgradeImage: file(relativePath: { eq: "upgrade.png" }) {
            childImageSharp {
                fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
