import React, { Component, useRef, useEffect } from 'react'
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "gatsby";
import logo from "../images/logo.webp";
// import MessengerCustomerChat from 'react-messenger-customer-chat';
// import FacebookChat from "./common/FacebookChat";

import config from '../utils/siteConfig';

function wait2(cond1, ms) {
  if (cond1) {
      // console.log('done')
      xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid","searchView=list","id=my-store-35535303");
  } else {
      // console.log('keep waiting')
      setTimeout(wait2,ms,cond1,ms)
  }
}

function EcwidStore(props) {
    // const storeDiv =  useRef(null);
    // const scriptRef = useRef(null);
    // window.localStorage.setItem("show_ecwid_logs","true")
    // window.ecwid_script_defer = true;
    // window.ecwid_dynamic_widgets = true;
    // window.ec = window.ec || Object();
    // window.ec.storefront = window.ec.storefront || Object();
    // window.ec.enable_catalog_on_one_page = true;
    // window._xnext_initialization_scripts = [{
    //   widgetType: 'ProductBrowser',
    //   id: 'my-store-27283900',
    //   arg: ["id=productBrowser", "views=grid(20,3)"]  },
    //   {
    //     widgetType: 'CategoriesV2',
    //     id: 'my-categories-#####',
    //     arg: ["id=categoriesV2"]
    //   }
    //   ,
    //   {
    //     widgetType: 'SearchWidget',
    //     id: 'my-search-####',
    //     arg: ["id=searchWidget"]
    //   }
    // ];
  
    // var script = document.createElement('script');
    // script.charset = 'utf-8';
    // script.type = 'text/javascript';
    // script.src = 'https://app.ecwid.com/script.js?27283900';
    // script.defer = true;
    // script.ref=scriptRef;
    // console.log("into the store")
  
  
  useEffect(() => {
    wait2(window.Ecwid)
  })
  return (
    <>
      {/* <div id="my-search-####"></div> */}
      {/* <div id="my-categories-####"></div> */}
      {/* <div id="my-store-27283900" ref={storeDiv}></div> */}
      <div id="my-store-35535303" ></div>
      {/* <div className="ec-cart-widget"></div> */}
    </>

    );
}

export default EcwidStore

// export default class EcwidStore extends Component {
//     componentDidMount() {
//         window.ecwid_script_defer = true;
//         window.ecwid_dynamic_widgets = true;
//         const script = document.createElement("script");
//         script.src = "https://app.ecwid.com/script.js?27283900&data_platform=code&data_date=2020-04-20";
//         script.async = true;
//         script.charSet = 'utf-8';
//         script.setAttribute('data-cfasync', 'false');
//         document.body.appendChild(script);
//         xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid","searchView=list","id=my-store-27283900");
//     }
      
//     componentWillUnmount() {
//         // console.log("will unmount navbar")
//             let scriptToremove = "https://app.ecwid.com/script.js?27283900&data_platform=code&data_date=2020-04-20";
//             let allsuspects=document.getElementsByTagName("script");
//             // console.log("---allsuspects")
//             // console.log(allsuspects)
//             for (let i=allsuspects.length; i>=0; i--){
//                 if (allsuspects[i] && allsuspects[i].getAttribute("src")!==null 
//                     && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`)!== -1 ){
//                         // console.log("remove focusItem")
//                         // console.log(allsuspects[i])
//                         allsuspects[i].parentNode.removeChild(allsuspects[i])
//                 }
//             }
//     }

//     render() {
//         return (
//             <>
//                 <div id="my-store-27283900" />
//             </>
//         );
//     }
// }